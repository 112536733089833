import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { serialize } from "object-to-formdata";
import axios from "../utilities/axios";
import { clearHTMLTags } from "../utilities/clearHtmlTags";

const initialState = {
  articles: [],
  article: "",
  articlesStatus: "idle",
  updateArticleStatus: "idle",
  articleById: "",
  articleByIdStatus: "idle",
  updateCreateArticle: null,
  articlesError: null,
  categories: [],
  articleContent: [],
  createCategoryStatus: "idle",
  editCategoryStatus: "idle",
  img: "",
  func: "",
  UploadImageStatus: "idle",
  createStatusArticle: "idle",
  categoriesStatus: "idle",
  editedArticle: "",
  categoriesError: null,
  statusDeleteArticle: "idle",
  errorDeleteArticle: null,
  statusCreateArticle: "idle",
  errorCreateArticle: null,
  isArticleEmpty: false,
  deleteCategoryStatus: "idle",
};

export const getAllCategories = createAsyncThunk(
  "helpdesk/getAllCategories",
  async (websiteId, thunkApi) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${websiteId}/articles-category`
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkApi.dispatch(getAllArticles(websiteId));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getAllArticles = createAsyncThunk(
  "helpdesk/getAllArticles",
  async (websiteId) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${websiteId}/articles?page=1&limit=99999`
      );
      data = response.data.data.docs;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createArticles = createAsyncThunk(
  "helpdesk/createArticles",
  async (body, thunkAPI) => {
    let data;
    try {
      const { translations, ...rest } = body?.data;

      const response = await axios.post(
        `/websites/${body?.websiteID}/articles`,
        rest
      );
      data = response.data.data;
      if (response.status === 200) {
        const articleId = response.data.data?._id;
        await thunkAPI.dispatch(
          createTranslatedArticles({
            websiteID: body?.websiteID,
            data: translations?.map((el) => {
              return { articleId, ...el };
            }),
          })
        );
        await thunkAPI.dispatch(getAllArticles(body.websiteID));

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createTranslatedArticles = createAsyncThunk(
  "helpdesk/createTranslatedArticles",
  async (body) => {
    let data;
    try {
      const { websiteID, ...rest } = body;
      rest?.data?.forEach(async (el) => {
        const response = await axios.post(
          `/websites/${websiteID}/article-translations`,
          el
        );
        data = response.data.data;
      });

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updateTranslatedArticles = createAsyncThunk(
  "helpdesk/updateTranslatedArticles",
  async (body) => {
    let data;
    try {
      const { websiteID, ...rest } = body;
      rest?.data?.forEach(async (el) => {
        const { id, ...Data } = el;
        const response = await axios.patch(
          `/websites/${websiteID}/article-translations/${id}`,
          Data
        );
        data = response.data.data;
      });

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getOneArticleById = createAsyncThunk(
  "helpdesk/getOneArticleById",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/articles/${body.id}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateArticle = createAsyncThunk(
  "helpdesk/updateArticle",
  async (body, thunkAPI) => {
    let data;
    try {
      const { translations, ...rest } = body?.data;

      const response = await axios.patch(
        `/websites/${body.websiteID}/articles/${body.id}`,
        rest
      );
      data = response.data.data;
      if (response.status === 200) {
        const articleId = body.id;

        if (translations?.filter((el) => el?.id !== "")?.length > 0) {
          await thunkAPI.dispatch(
            updateTranslatedArticles({
              websiteID: body?.websiteID,
              data: translations?.filter((el) => el?.id !== ""),
            })
          );
        }

        if (translations?.filter((el) => el?.id === "")?.length > 0) {
          await thunkAPI.dispatch(
            createTranslatedArticles({
              websiteID: body?.websiteID,
              data: translations
                ?.filter((el) => el?.id === "")
                ?.map((el) => {
                  const { id, ...rest } = el;
                  return { articleId, ...rest };
                }),
            })
          );
        }

        await thunkAPI.dispatch(getAllArticles(body.websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createCategory = createAsyncThunk(
  "helpdesk/createCategory",
  async (body, thunkAPI) => {
    let data;
    try {
      const { websiteID, ...rest } = body;
      const Data = serialize(rest, { indices: true });
      const response = await axios.post(
        `/websites/${body.websiteID}/articles-category`,
        Data
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getAllCategories(body.websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const editCategory = createAsyncThunk(
  "helpdesk/editCategory",
  async (body, thunkAPI) => {
    let data;
    const { websiteID, categoryId, ...rest } = body;
    const Data = serialize(rest, { indices: true });
    try {
      const response = await axios.patch(
        `/websites/${body.websiteID}/articles-category/${body?.categoryId}`,
        Data
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getAllCategories(body.websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "helpdesk/deleteCategory",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(
        `/websites/${body.websiteID}/articles-category/${body?.id}`
      );

      if (response.status === 200) {
        thunkAPI.dispatch(getAllCategories(body.websiteID));
        return response.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteArticle = createAsyncThunk(
  "helpdesk/deleteArticle",
  async (body) => {
    let data;
    try {
      const response = await axios.delete(
        `/websites/${body.websiteID}/articles/${body.articleID}`
      );

      if (response.status === 200) {
        return body.articleID;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const UploadImage = createAsyncThunk(
  "helpdesk/UploadImage",
  async (body) => {
    let data;
    try {
      const response = await axios.post(
        `/websites/${body.websiteID}/articles/uploadImage`,
        serialize({ file: body.file }, { indices: true })
      );

      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    getArticleContent: (state, action) => {
      clearHTMLTags(action.payload?.data)?.trim() === ""
        ? (state.articleContent = state.articleContent.filter(
            (item) => item?.language !== action.payload?.language
          ))
        : (state.articleContent = state.articleContent.find(
            (el) => el?.language === action.payload?.language
          )
            ? state.articleContent.map((el) =>
                el?.language === action.payload?.language
                  ? (() => {
                      return (
                        clearHTMLTags(action.payload?.data)?.trim() !== "" && {
                          ...el,
                          articleContent: action.payload?.data,
                        }
                      );
                    })()
                  : el
              )
            : clearHTMLTags(action.payload?.data)?.trim() !== "" &&
              (state.articleContent = [
                ...state.articleContent,
                {
                  articleContent: action.payload.data,
                  language: action.payload.language,
                },
              ]));

      state.isArticleEmpty = action.payload.isEmpty;
      state.func = action.payload.func;
    },
    getOneArticle: (state, action) => {
      localStorage.setItem("article", JSON.stringify(action.payload));
      state.article = action.payload;
    },
    emptyArticleContent: (state) => {
      state.articleContent = [];
      state.articleById = "";
    },
    getEditedArticle: (state, action) => {
      state.editedArticle = action.payload;
    },
  },
  extraReducers: {
    [getAllArticles.pending]: (state) => {
      state.articlesStatus = "loading";
    },
    [getAllArticles.fulfilled]: (state, action) => {
      state.articlesStatus = "succeeded";
      state.articles = action.payload;
    },
    [getAllArticles.rejected]: (state, action) => {
      state.articlesStatus = "failed";
      state.articlesError = action.payload;
    },
    [getOneArticleById.pending]: (state) => {
      state.articleByIdStatus = "loading";
    },
    [getOneArticleById.fulfilled]: (state, action) => {
      state.articleByIdStatus = "succeeded";
      state.articleById = action.payload;
    },
    [getOneArticleById.rejected]: (state, action) => {
      state.articleByIdStatus = "failed";
      state.articlesError = action.payload;
    },
    [createCategory.pending]: (state) => {
      state.createCategoryStatus = "loading";
    },
    [createCategory.fulfilled]: (state, action) => {
      state.createCategoryStatus = "succeeded";
      state.articles = action.payload;
    },
    [createCategory.rejected]: (state) => {
      state.createCategoryStatus = "failed";
    },
    [editCategory.pending]: (state) => {
      state.editCategoryStatus = "loading";
    },
    [editCategory.fulfilled]: (state) => {
      state.editCategoryStatus = "succeeded";
    },
    [editCategory.rejected]: (state) => {
      state.editCategoryStatus = "failed";
    },

    [UploadImage.pending]: (state) => {
      state.UploadImageStatus = "loading";
    },
    [UploadImage.fulfilled]: (state, action) => {
      state.UploadImageStatus = "succeeded";

      state.img = action.payload;
    },
    [UploadImage.rejected]: (state, action) => {
      state.UploadImageStatus = "failed";
      state.articlesError = action.payload;
    },
    [getAllCategories.pending]: (state) => {
      state.categoriesStatus = "loading";
    },
    [getAllCategories.fulfilled]: (state, action) => {
      state.categoriesStatus = "succeeded";
      state.categories = action.payload;
    },
    [getAllCategories.rejected]: (state, action) => {
      state.categoriesStatus = "failed";
      state.categoriesError = action.payload;
    },
    [deleteArticle.pending]: (state) => {
      state.statusDeleteArticle = "loading";
    },
    [deleteArticle.fulfilled]: (state, action) => {
      state.statusDeleteArticle = "succeeded";
      state.articles = state.articles.filter(
        (article) => article._id !== action.payload
      );
    },
    [deleteArticle.rejected]: (state, action) => {
      state.statusDeleteArticle = "failed";
      state.errorDeleteArticle = action.payload;
    },
    [createArticles.pending]: (state) => {
      state.createStatusArticle = "loading";
    },
    [createArticles.fulfilled]: (state) => {
      state.statusDeleteArticle = "succeeded";
      // state.articles = [...state.articles, action.payload];
    },
    [createArticles.rejected]: (state, action) => {
      state.statusDeleteArticle = "failed";
      state.errorDeleteArticle = action.payload;
    },
    [updateArticle.pending]: (state) => {
      state.createStatusArticle = "loading";
    },
    [updateArticle.fulfilled]: (state) => {
      state.statusDeleteArticle = "succeeded";
      // state.articles = state.articles.map((item) =>
      //   item._id === action.payload._id ? action.payload : item
      // );
    },
    [updateArticle.rejected]: (state, action) => {
      state.statusDeleteArticle = "failed";
      state.errorDeleteArticle = action.payload;
    },
    [deleteCategory.pending]: (state) => {
      state.statusdeleteCategory = "loading";
    },
    [deleteCategory.fulfilled]: (state) => {
      state.statusdeleteCategory = "succeeded";
    },
    [deleteCategory.rejected]: (state) => {
      state.statusdeleteCategory = "failed";
    },
  },
});
export const reducer = slice.reducer;
export const { getArticleContent, emptyArticleContent, getOneArticle } =
  slice.actions;

export default slice;
